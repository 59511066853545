import ErrorIcon from '@mui/icons-material/Error';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';

const wrapperStyles = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '15px 20px',
};

const iconStyles = {
  fontSize: '92px',
};

const AppError = () => {
  const { t } = useTranslation('form');

  return (
    <Box sx={wrapperStyles}>
      <ErrorIcon color='error' sx={iconStyles} />
      <Typography align='center' variant='body1'>
        {t('app_error')}
      </Typography>
    </Box>
  );
};

export default AppError;
