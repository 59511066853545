import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom/client';

import AppError from './components/AppError';
import { SentryEnvTracesSampleRate } from './constants/sentry.constants';
import { getEnvWithOptionalUserName } from './helpers/environment';
import reportWebVitals from './reportWebVitals';
import theme from './theme/theme';
import { BuildTarget } from './types/environment.types';

const initSentry = () => {
  if (process.env.REACT_APP_ENV === 'local') {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: getEnvWithOptionalUserName(),
    integrations: [new BrowserTracing()],
    release: process.env.REACT_APP_SENTRY_RELEASE,
    tracesSampleRate: SentryEnvTracesSampleRate[process.env.REACT_APP_ENV],
  });
};

// This line should always occur before first react render
initSentry();

const importBuildTarget = async () => {
  if (process.env.REACT_APP_BUILD_TARGET === BuildTarget.CARERS_SIGNUP_FORM) {
    await import('./carers-signup-form/i18n/config');

    return import('./carers-signup-form/App');
  }

  throw new Error(`No such build target: ${process.env.REACT_APP_BUILD_TARGET}`);
};

const main = async () => {
  const { default: App } = await importBuildTarget();

  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

  root.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Sentry.ErrorBoundary fallback={() => <AppError />}>
          <App />
        </Sentry.ErrorBoundary>
      </ThemeProvider>
    </React.StrictMode>,
  );

  reportWebVitals();
};

main();
