import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const primaryColor = '#6DF3AE';
const secondaryColor = '#00214E';

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '@media (hover: none)': {
            '&:hover': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: secondaryColor,
              },
            },
            '&.Mui-focused': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: primaryColor,
              },
            },
          },
        },
        notchedOutline: {
          borderColor: secondaryColor,
          borderRadius: '8px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: primaryColor,
      light: '#D2FFE8',
      dark: '#2DDB81',
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: secondaryColor,
      secondary: '#57647E',
    },
    background: {
      default: '#FAFFFA',
      paper: '#FFF',
    },
  },
  typography: {
    fontFamily: [
      'Quicksand',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});

const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
