enum BuildTarget {
  CARERS_SIGNUP_FORM = 'CARERS_SIGNUP_FORM',
}

enum Env {
  LOCAL = 'local',
  TEST = 'test',
  DEV = 'dev',
  PROD = 'prod',
}

// Ex: local-laurent
export type EnvWithOptionalUserName = Env | string;

export { BuildTarget, Env };
