import { Env, EnvWithOptionalUserName } from '../types/environment.types';

const getEnvWithOptionalUserName = (): EnvWithOptionalUserName => {
  const environment = process.env.REACT_APP_ENV;
  const devUser = process.env.REACT_APP_USER;

  if (environment !== Env.LOCAL) {
    return environment;
  }

  if (!devUser || devUser === 'null') {
    throw new Error('In local env, the user should be defined');
  }

  return `${environment}-${devUser}`;
};

export { getEnvWithOptionalUserName };
