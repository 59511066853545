import { Env } from '../types/environment.types';

const SentryEnvTracesSampleRate: Record<Env, number> = {
  [Env.LOCAL]: 0,
  [Env.TEST]: 0,
  [Env.DEV]: 0.01,
  [Env.PROD]: 0.1,
};

export { SentryEnvTracesSampleRate };
